<template>
  <div>
    <section class="mt-[3rem] mb-[1rem]">
      <div class="w-full mx-auto lg:w-[95%] 2xl:w-[70%]">
        <div>
          <h1 class="text-center text-3xl text-black capitalize">
            our alumini work here
          </h1>
        </div>

        <div class="h-auto">
          <div class="carousel relative w-full">
            <div class="slick_alumini-workss">
              <template v-for="el in hiring_partners" :key="el">
                <div class="min-h-[120px] p-4 cursor-pointer">
                  <img
                    :src="el.image"
                    alt=""
                    class="w-[290px] bg-white object-contain mx-auto rounded-lg shadow-xl"
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import $ from "jquery";
import "slick-carousel";
export default {
  name: "CompaniesView",

  data() {
    return {
      hiring_partners: [
        {
          image: require("@/assets/Online/kellogs.webp"),
        },
        {
          image: require("@/assets/Online/johnson.webp"),
        },
        {
          image: require("@/assets/Online/infosys.webp"),
        },
        {
          image: require("@/assets/Online/icici.webp"),
        },
        {
          image: require("@/assets/Online/honeywell.webp"),
        },
        {
          image: require("@/assets/Online/hitachi.webp"),
        },
        {
          image: require("@/assets/Online/hector.webp"),
        },
        {
          image: require("@/assets/Online/hdfc.webp"),
        },
        {
          image: require("@/assets/Online/google.webp"),
        },
        {
          image: require("@/assets/Online/flipkart.webp"),
        },
        {
          image: require("@/assets/Online/facebook.webp"),
        },
        {
          image: require("@/assets/Online/ey.webp"),
        },
        {
          image: require("@/assets/Online/standard.webp"),
        },
        {
          image: require("@/assets/Online/pwc.webp"),
        },
        {
          image: require("@/assets/Online/mtr.webp"),
        },
        {
          image: require("@/assets/Online/kpmg.webp"),
        },
      ],
    };
  },
  beforeMount() {
    const elementToRemoveDes = document.querySelector(".slick_alumini-workss");

    if (elementToRemoveDes) {
      elementToRemoveDes.remove();
    }
  },
  mounted() {
    $(`.slick_alumini-workss`).slick({
      infinite: true,
      autoplay: true,
      slidesToShow: 6,
      slidesToScroll: 2,
      prevArrow: false,
      nextArrow: false,
      // dots: true,
      // dotsClass: "slick-dotsCompany",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    });
  },
};
</script>
<style>
/* .slick-dotsCompany {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 1rem 0;
      list-style-type: none;
      gap: 8px;
    }
    .slick-dotsCompany li button {
      width: 0.25rem;
      height: 0.25rem;
      background-color: #001c54;
    }
    .slick-dotsCompany button {
      display: block;
      width: 1rem;
      height: 1rem;
      padding: 0;
      border: none;
      border-radius: 15px;
      background-color: silver;
      text-indent: -9999px;
    }
    .slick-dotsCompany li.slick-active button {
      background-color: #fdd335;
      width: 1.5rem;
      height: 0.25rem;
    } */
</style>
