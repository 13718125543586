<template>
  <div>
    <div class="lg:w-[95%] 2xl:w-[70%] mx-auto">
      <div class="px-3 md:px-4">
        <div
          class="flex flex-col lg:flex-row gap-2 justify-between md:gap-9 bg-gradient-to-b from-[#0f2477] to-[#28448d] text-white py-4 px-2 md:px-[50px]"
        >
          <div class="flex items-center gap-2">
            <img
              src="@/assets/landingpage/ItAllAddsUpicon2.webp"
              alt=""
              srcset=""
            />
            <p class="text-sm">Program Fee Starting at INR 61,000/-*</p>
          </div>
          <div class="flex items-center gap-2">
            <img src="@/assets/landingpage/ItAllAddsUpicon1.webp" alt="" />
            <p class="text-sm">Free Access to Paid Linkedin learning</p>
          </div>
        </div>
      </div>
    </div>
    <section
      class="w-full lg:w-[95%] 2xl:w-[70%] mx-auto px-5 md:px-4 lg:px-0 py-3 md:py-6 lg:py-8"
    >
      <h3
        class="text-[24px] md:text-[35px] font-semibold text-center capitalize"
      >
        About Online Degree Program
      </h3>
      <p class="hidden text-center lg:flex flex-col lg:gap-3 py-2"></p>

      <div class="w-full px-5 md:px-4">
        <div>
          <div class="flex justify-center gap-3">
            <div
              v-for="(electiveStatic, index) in electiveStaticData"
              :key="index"
              @click="activeTab = electiveStatic.title"
              :class="{
                'border-b-[6px] border-black':
                  activeTab === electiveStatic.title,
                '': activeTab !== electiveStatic.title,
              }"
            >
              <button
                class="capitalize text-sm md:text-2xl px-[7px] py-[8px] md:px-[10px] md:py-[16px] text-black font-semibold"
              >
                {{ electiveStatic.title }}
              </button>
            </div>
          </div>
        </div>
        <div class="py-3">
          <p
            v-html="getDescription(activeTab)"
            :class="[
              isExpandedDescription
                ? 'text-center flex flex-col gap-2 font-medium'
                : 'text-center line-clamp-3 md:line-clamp-2',
            ]"
            class="text-sm lg:text-[18px]"
          ></p>
          <button @click="toggleReadMore" class="">
            {{ isExpandedDescription ? "Read less..." : "Read more..." }}
          </button>
          <div class="py-2 hidden md:block">
            <h4 class="text-[24px] font-semibold text-center hidden md:block">
              Electives offered
            </h4>
            <div class="flex flex-col lg:flex-row lg:flex-wrap gap-4 py-2">
              <ul
                v-for="offered in getOffered(activeTab)"
                :key="offered"
                class=""
              >
                <li class="flex items-start gap-3 lg:w-[350px] text-left">
                  <img
                    src="@/assets/landingpage/electives-offered-icon.webp"
                    alt="Checked-icon"
                  />
                  {{ offered }}
                </li>
              </ul>
            </div>
          </div>
          <div class="py-2 md:hidden">
            <h4 class="text-[24px] font-semibold text-center hidden md:block">
              Electives offered
            </h4>
            <div class="flex flex-col lg:flex-row lg:flex-wrap gap-4 py-2">
              <ul
                v-for="offered in getOffered(activeTab).slice(start, end)"
                :key="offered"
                class=""
              >
                <li class="flex items-start gap-3 lg:w-[400px] text-left">
                  <img
                    src="@/assets/landingpage/electives-offered-icon.webp"
                    alt="Checked-icon"
                  />
                  {{ offered }}
                </li>
              </ul>
              <div class="flex justify-center">
                <button
                  v-if="
                    getOffered(activeTab).length > 8 &&
                    getOffered(activeTab).length > end
                  "
                  @click="viewMore"
                  class="text-[#2e4c8c] text-[16px] text-center button-cta flex items-center gap-2"
                >
                  Load More
                  <img
                    src="@/assets/landingpage/loadmore_360.webp"
                    alt="loadmore-icon"
                    class="w-3"
                  />
                </button>
                <button
                  v-if="
                    getOffered(activeTab).length < end &&
                    getOffered(activeTab).length > 8
                  "
                  @click="viewLess"
                  class="text-[#2e4c8c] text-[16px] text-center button-cta flex items-center gap-2"
                >
                  Load Less
                  <img
                    src="@/assets/landingpage/loadmore_360.webp"
                    alt="loadmore-icon"
                    class="w-3 rotate-180"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div
      class="w-full lg:w-[95%] 2xl:w-[70%] mx-auto px-5 md:px-4 py-3 flex flex-col gap-3"
    >
      <h4 class="text-[18px] lg:text-[20px] text-left font-semibold">
        Eligibility
      </h4>
      <ul class="flex flex-wrap items-start gap-2 text-left corrected">
        <li class="flex gap-2">
          <img
            src="@/assets/landingpage/check.webp"
            alt=""
            srcset=""
            class="w-4 h-4 mt-1"
          />
          <p>
            Pass in an Undergraduate Program of a minimum duration of three (3)
            years in any stream from a UGC Recognized University, with a minimum
            aggregate of 50% or an equivalent letter/numerical grade.
          </p>
        </li>
        <li class="flex gap-2">
          <img
            src="@/assets/landingpage/check.webp"
            alt=""
            srcset=""
            class="w-4 h-4 mt-1"
          />
          <p>A relaxation of 5% shall be given to SC/ST candidates.</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "OnlineDegree",
  // props: ['degree'],

  data() {
    return {
      start: 0,
      end: 8,
      isExpandedDescription: false,
      activeTab: "MBA",
      electiveStaticData: [
        {
          title: "MBA",
          description:
            "<p> An Online MBA is the best choice for those who wish to advance their careers in business and management. This degree opens up a plethora of career opportunities, increases your earning potential, helps you gain technical and management skills, and grows your professional network. <br> Our Online MBA is a two-year degree program that offers foundation courses, core courses, electives, and a comprehensive master thesis intermediary, apart from an option to pursue a cross-functional and open elective. The flexibility of the program not only allows the learners to learn on weekends but also from anywhere and anytime, at their own pace and comfort. It also gives them the opportunity to learn while working, thus making the program suitable for both students and working professionals. </p>",
          Offered: [
            "Human Resource Management",
            "Finance",
            "Marketing",
            "General Management",
            "Systems and Operations Management",
            "Finance and Marketing",
            "Human Resource Management and Finance",
            "Marketing and Human Resource Management",
            "Project Management",
            "Information Technology Management",
            "Healthcare Management",
            "Logistics & Supply Chain Management ",
            "Business Intelligence & Analytics ",
            "Entrepreneurship and Leadership",
            "International Business Management",
            // "Sports Management",
            "Data Science & Analytics",
            "Digital Marketing & E-commerce",
            "Digital Finance",
            "Integrated Marketing",
            "Strategic HRM",
            "Banking & Finance ",
            "International Finance (Accredited by ACCA & UK)",
          ],
        },
        {
          title: "MCA",
          description:
            "<p>Our Online MCA is a two-year degree program that offers foundation courses, core courses, and a comprehensive project, apart from an option to pursue a cross-functional and open elective. <br> The core programs incorporate AICTE's model curriculum as prescribed for a full-time Online MCA Program. They have been designed keeping in mind the industry's requirements to provide in-depth knowledge of the concepts and technologies required to develop software applications. Our Online MCA curriculum is intended to increase a learner's technical skills and abilities, thus offering a way to accelerate one's career for corporate roles in the IT domain. The program is engineered in a way that caters to both students and working professionals, instilling in them core technical abilities as they master the tech domain.</p>",
          Offered: [
            "Computer Science & IT",
            "Cyber Security ",
            "Data Analytics",
            "Machine Learning",
            "Full Stack Development",
            "Cloud Computing",
            "Data Science",
            "Artificial Intelligence",
          ],
        },
        {
          title: "M.Com",
          description:
            "<p>The Online M.Com (Master of Commerce) is a two-year degree program that gives you a comprehensive understanding of various professional streams like accounting, finance, taxation, banking, insurance, etc. Whether you're pursuing this program from India or elsewhere, having an understanding of commerce and its intricacies will benefit you greatly in the long run if you are thinking of entering the world of commerce. <br> Along with functional and domain expertise, the Online M.Com Program will also equip you with the competencies and skills required to boost your career into leadership and managerial roles. The unique component of a research project in your curriculum requires you to conduct in-depth research in your area of interest as it will allow you to practice your learnings. You will also be encouraged to publish your research with the support of a mentor.</p>",
          Offered: [
            "Strategic Accounting & Finance (Accredited by CPA & US)",
            "International Finance (Accredited by ACCA & UK)",
            "Accounting & Finance",
          ],
        },
        {
          title: "MA",
          description:
            "<p>An Online MA Program is the best choice for those who wish to deepen their understanding and expertise in a specific field within the arts and humanities. Learners delve into advanced theoretical concepts, critical analysis, and research methodologies relevant to their chosen discipline. Through rigorous coursework and case studies, they develop refined communication, creativity, and problem-solving skills, preparing them for careers in academia, research, cultural institutions, or various industries requiring a nuanced understanding of human expression and culture.</p>",
          Offered: ["English", "Economics", "Public Policy and Governance"],
        },
        {
          title: "BBA",
          description:
            "<p>Our Online BBA Program is a three-year degree that prepares you for entry-level roles in your chosen area of electives and takes you through the specific needs of a business. It will give you a comprehensive understanding of emerging business trends and technologies and an in-depth study of the inner workings of an organization. <br> We have designed the Online BBA Program to provide you with a broad overview of all essentials of business and management while providing a deep dive into the functional area of electives. Along with functional and domain expertise, the Online BBA Degree will also equip you with the competencies and skills required to kickstart your career in various business and management roles.</p>",
          Offered: ["Human Resource Management", "Finance", "Marketing"],
        },
        {
          title: "B.Com",
          description:
            "<p>The complexity of business operations today demands a blend of accounting and finance expertise. Our Online B.Com (Bachelor of Commerce) is a three-year degree program that will prepare you for a broad spectrum of managerial skills and understanding streams like finance, accounting, economics, law, insurance, management, taxation, etc. <br> Along with functional and domain expertise, the Online B.Com Degree Program will also equip you with the competencies and skills required to advance your career into leadership and strategic roles, giving you that necessary edge to compete and succeed.</p>",
          Offered: [
            "International Finance & Accounting (Accredited by ACCA & UK)",
          ],
        },
      ],
    };
  },
  methods: {
    getDescription(title) {
      return (
        this.electiveStaticData.find((item) => item.title === title)
          ?.description || ""
      );
    },
    getOffered(title) {
      return (
        this.electiveStaticData.find((item) => item.title === title)?.Offered ||
        []
      );
    },
    toggleReadMore() {
      this.isExpandedDescription = !this.isExpandedDescription;
    },

    viewMore() {
      this.end += 8;
    },
    viewLess() {
      this.end = 8;
    },
  },
};
</script>

<style scoped>
.corrected ul li {
  padding-left: 20px;
  background: url("data:image/webp;base64,UklGRtQBAABXRUJQVlA4WAoAAAAQAAAAEwAAEwAAQUxQSBgBAAABkHPbtrHnknds27bN1rZau7Lt1GZr204q287H2Hz9RsQEgNIge+b/5/VsrjFoi5c/E6Zryqqmfh4rpahpLRFq9LkAcGpXEda1KaQ3bjxB2+X/jgLA2kRwBr02Hx2MsCdkgf4UgitD029xOkzi+CFxMqj+Wg7a5tcnUkDNXfRnOAB2CqOz3xYAwj4a77wAoakEwPTy1BQAXN87HwMBjl5ChOGvG3NQ+ry3EVIA8Lb+/D03BtW017L9ERYAvK17FqDKNHLslEqwBAB2SVC3fivmU/q1yAt6OcavFYAgQj0rHczlhHAAyCN06dLQbicUMVAwxX5e13moiCi71/z7jGcGdeOm6/efz5+PmyZj0Ckf1bF5vNkVqwiqVlA4IEAAAABwAgCdASoUABQAAAAAJZQDI0UEuAfoBeABYQuAAP7/vAS//lSHe7HYS3/lSH/I48LqwQv/kN5tmI9d1tRiAAAAUFNBSU4AAAA4QklNA+0AAAAAABAASAAAAAEAAQBIAAAAAQABOEJJTQQoAAAAAAAMAAAAAj/wAAAAAAAAOEJJTQRDAAAAAAAOUGJlVwEQAAYAZAAAAAA=")
    left 1px no-repeat;

  background-size: 11px;
  margin-bottom: 25px;
  list-style-type: none !important;
}
</style>
