<template>
  <div class="overflow-x-hidden scroll-smooth">
    <!-- header start-->
    <section class="w-full h-full">
      <div
        class="bg-gradient-to-b from-[#0f2477] to-[#28448d] py-4 lg:py-4 px-5"
      >
        <div class="w-full lg:w-[90%] 2xl:w-[90%] mx-auto">
          <div class="hidden md:flex justify-between items-center">
            <img
              src="@/assets/landingpage/JO-logo.webp"
              alt="Jain-online"
              srcset=""
              class="md:h-[40px] lg:h-[50px]"
            />
            <!-- <a href="tel:+918626049876">
              <p
                class="text-[#f8c300] px-6 py-3 text-[20px] rounded-3xl flex gap-2 items-center"
              >
                <img
                  src="@/assets/landingpage/call-icon.webp"
                  alt="call-icon"
                />
                +91 8626049876
              </p>
            </a> -->
          </div>
          <div class="flex justify-between items-center md:hidden">
            <img
              src="@/assets/landingpage/JO-logo.webp"
              alt=""
              srcset=""
              class="w-[180px] h-[30px]"
            />
            <!-- <a href="tel:+918626049876" class="bg-white rounded-full p-1">
              <img
                src="@/assets/landingpage/call-icon.webp"
                alt="call-icon text-black w-4 h-4"
              />
            </a> -->
          </div>
        </div>
      </div>
    </section>
    <!-- header end-->
    <section class="pb-3 md:pb-6 lg:pb-8">
      <div
        class="hidden md:block bg-[#123177] lg:h-[580px] 2xl:h-[600px] px-5 py-2 md:py-0 md:px-4 2xl:p-0 relative"
      >
        <!-- Desktop Banner -->
        <div
          class="w-full lg:w-[95%] 2xl:w-[90%] lg:h-[560px] 2xl:h-[660px] mx-auto"
        >
          <div class="hidden lg:flex lg:justify-between items-center h-full">
            <!-- content -->
            <div class="flex flex-col gap-4 pb-[80px]">
              <h1 class="text-[35px] text-left font-bold text-white">
                Online Degree Programs
              </h1>
              <div class="flex bg-yellow-400 w-[280px] py-1 rounded-md">
                <button
                  v-for="(degree, index) in degrees"
                  :key="degree"
                  :class="[
                    index + 1 < degrees.length
                      ? 'border-r border-[#001c54]'
                      : '',
                  ]"
                  class="w-full px-1 font-semibold text-xs text-[#001c54]"
                >
                  {{ degree }}
                </button>
              </div>

              <ul
                class="text-white flex flex-col gap-3 list-inside"
                v-for="key in keys"
                :key="key"
              >
                <div class="flex gap-2 items-center">
                  <img
                    src="@/assets/Online/white-check.webp"
                    alt=""
                    srcset=""
                    class="w-4 h-4"
                  />
                  <p class="text-[18px]">{{ key }}</p>
                </div>
              </ul>
              <div class="flex gap-2">
                <img
                  src="@/assets/Online/banner-icon1.webp"
                  alt="NAAC-icon"
                  srcset=""
                  class="w-[80px] object-contain"
                />
                <img
                  src="@/assets/Online/banner-icon2.webp"
                  alt="AICTE-icon"
                  srcset=""
                  class="w-[80px] object-contain"
                />
                <img
                  src="@/assets/Online/banner-icon3.webp"
                  alt="AUAP-icon"
                  srcset=""
                  class="w-[80px] object-contain"
                />
                <img
                  src="@/assets/Online/banner-icon4.webp"
                  alt="WUR-icon"
                  srcset=""
                  class="w-[80px] object-contain"
                />
              </div>
            </div>
            <!-- banner image -->
            <div class="flex justify-center">
              <div class="absolute top-0">
                <img
                  src="@/assets/landingpage/Banner-image.webp"
                  alt="banner-image"
                  class="w-[350px] 2xl:w-[400px] h-[630px] object-cover rounded-b-3xl"
                  srcset=""
                />
              </div>
            </div>
            <!-- thankyou widget-->
            <div class="hidden lg:flex justify-center items-center h-[500px]">
              <div class="py-[50px] lg:py-[150px] px-5 lg:px-0">
                <div
                  class="lg:w-[350px] h-[182px] bg-[#cfd5e4] p-[40px] font-semibold text-[18px] lg:text-[20px]"
                >
                  <strong>Thank you</strong> for your inquiry. Someone from our
                  team <br />
                  will be in touch with you shortly.
                </div>
              </div>
            </div>
            <!-- thankyou widget -->
          </div>
        </div>
      </div>
      <!-- Mobile Banner -->
      <div class="w-full lg:hidden bg-[#123177]">
        <div class="md:w-[500px] mx-auto flex flex-col gap-3 px-5 py-4">
          <div class="flex flex-col gap-2 px-2">
            <!-- content -->
            <h1 class="text-[25px] font-bold text-white">
              Online Degree Programs
            </h1>
            <div class="flex bg-yellow-400 w-[280px] py-1 rounded-md">
              <button
                v-for="(degree, index) in degrees"
                :key="degree"
                :class="[
                  index + 1 < degrees.length ? 'border-r border-[#001c54]' : '',
                ]"
                class="w-full px-1 font-semibold text-xs text-[#001c54]"
              >
                {{ degree }}
              </button>
            </div>

            <div class="mt-3 flex flex-col justify-center mx-auto gap-3">
              <ul
                class="text-white pl-4 list-inside"
                v-for="key in keys"
                :key="key"
              >
                <div class="flex gap-2 items-center">
                  <img
                    src="@/assets/Online/white-check.webp"
                    alt=""
                    srcset=""
                    class="w-4 h-4"
                  />
                  <p class="text-[18px]">{{ key }}</p>
                </div>
              </ul>
              <div class="flex justify-center gap-2">
                <img
                  src="@/assets/Online/banner-icon1.webp"
                  alt="NAAC-icon"
                  srcset=""
                  class="w-16 h-16 object-contain"
                />
                <img
                  src="@/assets/Online/banner-icon2.webp"
                  alt="AICTE-icon"
                  srcset=""
                  class="w-16 h-16 object-contain"
                />
                <img
                  src="@/assets/Online/banner-icon3.webp"
                  alt="AUAP-icon"
                  srcset=""
                  class="w-16 h-16 object-contain"
                />
                <img
                  src="@/assets/Online/banner-icon4.webp"
                  alt="WUR-icon"
                  srcset=""
                  class="w-16 h-16 object-contain"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- thankyou widget-->
        <div class="flex justify-center items-center">
          <div class="py-[50px] lg:py-[150px] px-5 lg:px-0">
            <div
              class="lg:w-[350px] h-[182px] bg-[#cfd5e4] p-[40px] font-semibold text-[18px] lg:text-[20px]"
            >
              <strong>Thank you</strong> for your inquiry. Someone from our team
              <br />
              will be in touch with you shortly.
            </div>
          </div>
        </div>
        <!-- thankyou widget -->
      </div>
      <div class="hidden md:block h-10 w-full bg-white"></div>
    </section>
  </div>
</template>

<script>
export default {
  name: "DegreeHeader",
  // props: ['heading'],
  data() {
    return {
      degrees: ["MBA", "MCA", "M.Com", "BBA", "B.Com"],
      keys: [
        "40+ In-Demand Elective",
        "Live interactive classes",
        "Industry-aligned curriculum",
        "0 cost EMI option",
        "200+ hiring corporates",
      ],
    };
  },
  mounted() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://widgets.in5.nopaperforms.com/emwgts.js";
    document.body.appendChild(s);
  },
};
</script>

<style></style>
