<template>
  <div class="overflow-x-hidden scroll-smooth">
    <!-- header start-->
    <section class="w-full h-full">
      <div
        class="bg-gradient-to-b from-[#0f2477] to-[#28448d] py-4 lg:py-4 px-5"
      >
        <div class="w-full lg:w-[95%] 2xl:w-[90%] mx-auto">
          <div class="hidden md:flex justify-between items-center">
            <img
              src="@/assets/landingpage/JO-logo.webp"
              alt="Jain-online"
              srcset=""
              class="md:h-[40px] lg:h-[50px]"
            />
            <!-- <a href="tel:+91 8160604420">
              <p
                class="text-[#f8c300] px-6 py-3 text-[20px] rounded-3xl flex gap-2 items-center"
              >
                <img
                  src="@/assets/landingpage/call-icon.webp"
                  alt="call-icon"
                />
                +91 8160604420
              </p>
            </a> -->
          </div>
          <div class="flex justify-between items-center md:hidden">
            <img
              src="@/assets/landingpage/JO-logo.webp"
              alt=""
              srcset=""
              class="w-[180px] h-[30px]"
            />
            <!-- <a href="tel:+91 8160604420" class="bg-white rounded-full p-1">
              <img
                src="@/assets/landingpage/call-icon.webp"
                alt="call-icon text-black w-4 h-4"
              />
            </a> -->
          </div>
        </div>
      </div>
    </section>
    <!-- header end-->
    <section class="pb-3 md:pb-6 lg:pb-8">
      <div
        class="hidden md:block bg-[#123177] lg:h-[580px] 2xl:h-[600px] px-5 py-2 md:py-0 md:px-4 2xl:p-0 relative"
      >
        <!-- Desktop Banner -->
        <div
          class="w-full lg:w-[95%] 2xl:w-[90%] lg:h-[560px] 2xl:h-[660px] mx-auto"
        >
          <div class="hidden lg:flex lg:justify-between items-center h-full">
            <!-- content -->
            <div class="flex flex-col gap-4 pb-[80px]">
              <h1 class="text-[35px] text-left font-bold text-white">
                Online Degree Programs
              </h1>
              <div class="flex bg-yellow-400 w-[280px] py-1 rounded-md">
                <button
                  v-for="(degree, index) in degrees"
                  :key="degree"
                  :class="[
                    index + 1 < degrees.length
                      ? 'border-r border-[#001c54]'
                      : '',
                  ]"
                  class="w-full px-1 font-semibold text-xs text-[#001c54]"
                >
                  {{ degree }}
                </button>
              </div>

              <ul
                class="text-white flex flex-col gap-3 list-inside"
                v-for="key in keys"
                :key="key"
              >
                <div class="flex gap-2 items-center">
                  <img
                    src="@/assets/landingpage/white-check.webp"
                    alt=""
                    srcset=""
                    class="w-4 h-4"
                  />
                  <p class="text-[18px]">{{ key }}</p>
                </div>
              </ul>
              <div class="flex gap-2">
                <img
                  src="@/assets/landingpage/banner-icon1.webp"
                  alt="NAAC-icon"
                  srcset=""
                  class="w-[80px] object-contain"
                />
                <img
                  src="@/assets/landingpage/banner-icon2.webp"
                  alt="AICTE-icon"
                  srcset=""
                  class="w-[80px] object-contain"
                />
                <img
                  src="@/assets/landingpage/banner-icon3.webp"
                  alt="AUAP-icon"
                  srcset=""
                  class="w-[80px] object-contain"
                />
                <img
                  src="@/assets/landingpage/banner-icon4.webp"
                  alt="WUR-icon"
                  srcset=""
                  class="w-[80px] object-contain"
                />
              </div>
            </div>
            <!-- banner image -->
            <div class="flex justify-center">
              <div class="absolute top-0">
                <img
                  src="@/assets/landingpage/Banner-image.webp"
                  alt="banner-image"
                  class="w-[350px] 2xl:w-[400px] h-[630px] object-cover rounded-b-3xl"
                  srcset=""
                />
              </div>
            </div>

            <!-- form -->
            <div class="hidden lg:flex justify-center items-center h-[500px]">
              <div class="w-[350px] h-[500px] bg-[#001c54] pt-5">
                <div class="flex flex-col gap-3">
                  <h1 class="text-[#be8931] text-[23px]">
                    Get A Free Counseling
                  </h1>
                  <p class="text-white">in India's Finest University</p>
                  <form
                    action=""
                    @submit.prevent="submitP5Form"
                    class="flex flex-col gap-6"
                  >
                    <div>
                      <input
                        type="text"
                        v-model="fullName"
                        class="py-1 w-[290px] px-2"
                        placeholder="Enter Your Name"
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="email"
                        v-model="email"
                        class="py-1 w-[290px] px-2"
                        placeholder="Enter Your Email"
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="tel"
                        name=""
                        maxlength="10"
                        pattern="[0-9]{10}"
                        v-model="mobileNumber"
                        id=""
                        class="py-1 w-[290px] px-2"
                        placeholder="Enter Your Mobilenumber"
                        required
                      />
                    </div>
                    <div>
                      <select
                        name=""
                        id=""
                        @change="showCourses()"
                        v-model="selectedProgram"
                        class="w-[290px] py-2 px-2"
                        aria-placeholder="Select Your Course"
                        required
                      >
                        <option value="" disabled>Select Your Course</option>
                        <option
                          class="flex flex-col text-black"
                          :value="program.title"
                          v-for="(program, index) in programs"
                          :key="index"
                        >
                          {{ program.title }}
                        </option>
                      </select>
                    </div>
                    <!-- courses offered -->
                    <div>
                      <select
                        style="display: none"
                        name="courses"
                        id="courses"
                        v-model="selectedCourse"
                        class="w-[290px] py-2 px-2"
                        aria-placeholder="Select Your Course"
                        required
                      >
                        <option value="" disabled>Select Your Elective</option>
                        <option
                          class="flex flex-col text-black"
                          :value="course"
                          v-for="course in courseList"
                          :key="course"
                        >
                          {{ course }}
                        </option>
                      </select>
                    </div>

                    <div>
                      <select
                        name=""
                        id=""
                        v-model="selectedCity"
                        placeholder="Select Your State"
                        class="w-[290px] py-2 px-2"
                        aria-placeholder="Select Your State"
                        required
                      >
                        <option value="" disabled selected>
                          Select Your State
                        </option>
                        <option
                          :value="state"
                          v-for="state in states"
                          :key="state"
                        >
                          {{ state }}
                        </option>
                      </select>
                    </div>
                    <div>
                      <button class="text-[white] py-2 w-[290px] bg-[#f7860e]">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Mobile Banner -->
      <div class="w-full lg:hidden bg-[#123177]">
        <div class="md:w-[500px] mx-auto flex flex-col gap-3 px-5 py-4">
          <div class="flex flex-col gap-2 px-2">
            <!-- content -->
            <h1 class="text-[25px] font-bold text-white">
              Online Degree Programs
            </h1>
            <div class="flex bg-yellow-400 py-1 rounded-md">
              <button
                v-for="(degree, index) in degrees"
                :key="degree"
                :class="[
                  index + 1 < degrees.length ? 'border-r border-[#001c54]' : '',
                ]"
                class="w-full font-semibold text-xs text-[#001c54]"
              >
                {{ degree }}
              </button>
            </div>

            <div class="mt-3 flex flex-col justify-center mx-auto gap-3">
              <ul
                class="text-white pl-4 list-inside"
                v-for="key in keys"
                :key="key"
              >
                <div class="flex gap-2 items-center">
                  <img
                    src="@/assets/landingpage/white-check.webp"
                    alt=""
                    srcset=""
                    class="w-4 h-4"
                  />
                  <p class="text-[18px]">{{ key }}</p>
                </div>
              </ul>
              <div class="flex justify-center gap-2">
                <img
                  src="@/assets/landingpage/banner-icon1.webp"
                  alt="NAAC-icon"
                  srcset=""
                  class="w-16 h-16 object-contain"
                />
                <img
                  src="@/assets/landingpage/banner-icon2.webp"
                  alt="AICTE-icon"
                  srcset=""
                  class="w-16 h-16 object-contain"
                />
                <img
                  src="@/assets/landingpage/banner-icon3.webp"
                  alt="AUAP-icon"
                  srcset=""
                  class="w-16 h-16 object-contain"
                />
                <img
                  src="@/assets/landingpage/banner-icon4.webp"
                  alt="WUR-icon"
                  srcset=""
                  class="w-16 h-16 object-contain"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- form -->
        <div class="md:hidden flex justify-center items-center h-[500px]">
          <div class="w-[350px] h-[500px] bg-[#001c54] pt-5">
            <div class="flex flex-col gap-3">
              <h1 class="text-[#be8931] text-[23px]">Get A Free Counseling</h1>
              <p class="text-white">in India's Finest University</p>
              <form
                action=""
                @submit.prevent="submitP5Form"
                class="flex flex-col gap-6"
              >
                <div>
                  <input
                    type="text"
                    v-model="fullName"
                    class="py-1 w-[290px] px-2"
                    placeholder="Enter Your Name"
                    required
                  />
                </div>
                <div>
                  <input
                    type="email"
                    v-model="email"
                    class="py-1 w-[290px] px-2"
                    placeholder="Enter Your Email"
                    required
                  />
                </div>
                <div>
                  <input
                    type="tel"
                    name=""
                    maxlength="10"
                    pattern="[0-9]{10}"
                    v-model="mobileNumber"
                    id=""
                    class="py-1 w-[290px] px-2"
                    placeholder="Enter Your Mobilenumber"
                    required
                  />
                </div>
                <div>
                  <select
                    name=""
                    id=""
                    v-model="selectedProgram"
                    @change="showCourses()"
                    class="w-[290px] py-2 px-2"
                    required
                    aria-placeholder="Select Your Course"
                  >
                    <option value="" disabled>Select Your Course</option>
                    <option
                      class="flex flex-col text-black"
                      :value="program.title"
                      v-for="(program, index) in programs"
                      :key="index"
                    >
                      {{ program.title }}
                    </option>
                  </select>
                </div>
                <div>
                  <select
                    name=""
                    id=""
                    v-model="selectedCourse"
                    class="w-[290px] py-2 px-2"
                    aria-placeholder="Select Your Course"
                    required
                  >
                    <option value="" disabled>Select Your Elective</option>
                    <option
                      class="flex flex-col text-black"
                      :value="course"
                      v-for="course in courseList"
                      :key="course"
                    >
                      {{ course }}
                    </option>
                  </select>
                </div>
                <div>
                  <select
                    name=""
                    id=""
                    v-model="selectedCity"
                    class="w-[290px] py-2 px-2"
                    aria-placeholder="Select Your Course"
                    required
                  >
                    <option value="" disabled selected>
                      Select Your State
                    </option>
                    <option :value="state" v-for="state in states" :key="state">
                      {{ state }}
                    </option>
                  </select>
                </div>
                <div>
                  <button class="text-[white] py-2 w-[290px] bg-[#f7860e]">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden md:block h-10 w-full bg-white"></div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DegreeHeader",
  // props: ['heading'],
  data() {
    return {
      fullName: "",
      email: "",
      mobileNumber: "",
      selectedCity: "",
      selectedProgram: "",
      selectedCourse: "",
      degrees: ["MBA", "MCA", "M.Com", "MA", "BBA", "B.Com"],
      courseList: [],
      keys: [
        "40+ In-Demand Elective",
        "Live interactive classes",
        "Industry-aligned curriculum",
        "0 cost EMI option",
        "200+ hiring corporates",
      ],
      states: [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
      ],
      programs: [
        {
          title: "MBA",

          Offered: [
            "Human Resource Management",
            "Finance",
            "Marketing",
            "General Management",
            "Systems and Operations Management",
            "Finance and Marketing",
            "Human Resource Management and Finance",
            "Marketing and Human Resource Management",
            "Project Management",
            "Information Technology Management",
            "Healthcare Management",
            "Logistics & Supply Chain Management ",
            "Business Intelligence & Analytics ",
            "Entrepreneurship and Leadership",
            "International Business Management",
            // "Sports Management",
            "Data Science & Analytics",
            "Digital Marketing & E-commerce",
            "Digital Finance",
            "Integrated Marketing",
            "International Finance (Accredited by ACCA & UK)",
            "Strategic HRM",
            "Banking & Finance ",
          ],
        },
        {
          title: "MCA",

          Offered: [
            "Computer Science & IT",
            "Cyber Security ",
            "Data Analytics",
            "Machine Learning",
            "Full Stack Development",
            "Cloud Computing",
            "Data Science",
            "Artificial Intelligence",
          ],
        },
        {
          title: "M.Com",

          Offered: [
            "Strategic Accounting & Finance (Accredited by CPA & US)",
            "International Finance (Accredited by ACCA & UK)",
            "Accounting & Finance",
          ],
        },
        {
          title: "MA",

          Offered: ["M.A English", "MA- Public Policy", "Accounting & Finance"],
        },
        {
          title: "BBA",

          Offered: ["Human Resource Management", "Finance", "Marketing"],
        },
        {
          title: "B.Com",

          Offered: [
            "International Finance & Accounting (Accredited by ACCA & UK)",
          ],
        },
      ],
    };
  },
  methods: {
    showCourses() {
      document.getElementById("courses").style.display = "inline-block";
      console.log(this.selectedProgram);
      this.courseList =
        this.programs.find((item) => item.title === this.selectedProgram)
          ?.Offered || [];
    },
    submitP5Form() {
      const formData = {
        college_id: "5005",
        name: this.fullName,
        email: this.email,
        country_dial_code: "+91",
        mobile: this.mobileNumber,
        source: "timespro",
        secret_key: "d09faaa3691b094eb34737239e38622c",
        campus: this.selectedProgram,
        course: this.selectedCourse,
        state: this.selectedCity,
      };
      axios
        .post(`https://api.onlinejain.com/api/npf/dataporting/5005/`, formData)
        .then((response) => {
          console.log(response.data);
          this.$router.push("/lp/online/timespro/thankyou/");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

  mounted() {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://widgets.in5.nopaperforms.com/emwgts.js";
    document.body.appendChild(s);
  },
};
</script>

<style></style>
