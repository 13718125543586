<template>
  <div class="overflow-x-hidden">
    <OnlineBanner />
    <OnlineDEgree />
    <itAllAddUpViewVue />
    <MakeDreamsView />
    <AluminiSpeakView />
    <WorkView />
    <AdmissionView />
    <OnlineFaq />
    <FooterView />
  </div>
</template>

<script>
import OnlineBanner from "@/components/Timespro/OnlineBanner.vue";
import OnlineDEgree from "@/components/Timespro/OnlineDegree.vue";
import itAllAddUpViewVue from "@/components/Timespro/itAllAddUpView.vue";
import MakeDreamsView from "@/components/Timespro/MakeDreamsView.vue";
import AluminiSpeakView from "@/components/Timespro/AlumanaiSpeakView.vue";
import WorkView from "@/components/Timespro/WorkView.vue";
import AdmissionView from "@/components/Timespro/AdmissionView.vue";
import OnlineFaq from "@/components/Timespro/OnlineFaq.vue";
import FooterView from "@/components/Timespro/FooterView";

export default {
  name: "TimesPro",
  components: {
    OnlineBanner,
    OnlineDEgree,
    itAllAddUpViewVue,
    MakeDreamsView,
    AluminiSpeakView,
    WorkView,
    AdmissionView,
    OnlineFaq,
    FooterView,
  },
};
</script>

<style scoped></style>
