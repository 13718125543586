<template>
  <section class="!fixed z-50 bottom-0 w-full left-0 lg:hidden">
    <div class="flex items-center h-14">
      <div
        @click="scrollToTop"
        class="bg-[#263472] text-white w-full py-4 cursor-pointer"
      >
        Enroll Now
      </div>
      <!-- <a href="tel:+91 8160604420" class="w-full">
        <div
          class="bg-[#f3af1f] text-white w-full py-4 flex justify-center items-center gap-2"
        >
          <div>
            <img src="@/assets/Online/Iconmaterial-call.svg" alt="" srcset="" />
          </div>
          <div>Call</div>
        </div>
      </a> -->
    </div>
  </section>
</template>

<script>
export default {
  name: "FooterView",
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
