<template>
  <div
    class="w-full bg-gradient-to-b from-[#06235f] to-[#0e2374] md:bg-gradient-to-b md:from-[#f3f4f6] md:to-[#f3f4f6] md:pt-[1rem]"
  >
    <section class="w-full lg:w-[95%] 2xl:w-[70%] mx-auto px-5 md:p-4">
      <h1 class="text-[24px] md:text-[35px] hidden md:block">
        Frequently Asked Questions
      </h1>
      <h1 class="text-[24px] md:text-[35px] md:hidden text-[#f8c300]">
        Frequently Asked Questions
      </h1>
      <div class="flex justify-center items-center pt-2 md:pt-4">
        <div v-for="category in categories" :key="category">
          <button
            @click="selectedCategory = category"
            :class="{
              'border-b-[6px] border-[#f8c300] md:border-black text-[#f8c300]':
                selectedCategory === category,
              'border-b-[6px] text-white md:text-black':
                selectedCategory !== category,
            }"
            class="font-medium text-[14px] md:text-[20px] px-[10px] py-[5px] md:px-[30px] md:py-[10px] uppercase"
          >
            {{ category }}
          </button>
        </div>
      </div>
      <div class="py-5 md:py-6 flex flex-col gap-[10px] md:gap-3">
        <div>
          <!-- <h3
              class="hidden md:block text-[35px] text-[#1f1f1f] font-semibold text-center capitalize"
            >
              {{ selectedCategory }}
            </h3> -->
          <!-- <div v-if="selectedCategory === 'programs'">
              <div class="w-full mx-auto flex flex-col gap-[10px] md:gap-3">
                <div
                  v-for="(item, index) in this.faqs_list.slice(
                    this.start,
                    this.end,
                  )"
                  :key="index"
                  class="flex flex-col text-left md:bg-white md:border-4 md:border-gray-200 md:rounded-xl"
                >
                  <div
                    class="flex justify-between lg:gap-2 px-[15px] py-[11px] md:px-[25px] md:pt-[20px] cursor-pointer text-[#001c54]"
                    @click="toggleAccordin(index)"
                  >
                    <h3
                      v-html="item.title"
                      class="text-[14px] md:text-[15px] lg:text-[16px] text-white md:text-[#7e7e7e]"
                    ></h3>
                    <div>
                      <span
                        v-if="activeAccordin !== index"
                        class="pt-[10px] pr-[4px]"
                      >
                        <font-awesome-icon
                          icon="fa-angle-down"
                          class="text-[#f8c300] md:text-[#bb1e27]"
                        />
                      </span>
                      <span v-else class="pt-[3px] pl-[4px]">
                        <font-awesome-icon
                          icon="fa-angle-up"
                          class="text-[#f8c300] md:text-[#bb1e27]"
                        />
                      </span>
                    </div>
                  </div>
                  <p
                    class="text-sm pb-[10px] px-[15px] md:px-[25px] lg:text-md text-[#1f1f1f]"
                    v-show="activeAccordin === index"
                    v-html="item.details"
                  ></p>
                  <hr class="md:hidden" />
                </div>
                <div
                  v-if="this.end < this.faqs_list.length"
                  class="w-full md:flex md:justify-center md:items-center py-2"
                >
                  <button
                    @click="viewMore"
                    class="w-[140px] bg-[#f8c300] border rounded-xl md:rounded-lg py-[6px] md:py-[8px] font-semibold"
                  >
                    Load More!
                  </button>
                </div>
                <div v-else>
                  <button
                    v-if="this.end > 6"
                    @click="viewLess"
                    class="w-[140px] bg-[#f8c300] border rounded-xl md:rounded-lg py-[6px] md:py-[8px] font-semibold"
                  >
                    Load Less!
                  </button>
                </div>
              </div>
            </div> -->
          <div v-if="selectedCategory === 'admissions'">
            <div class="w-full mx-auto flex flex-col gap-[10px] md:gap-3">
              <div
                v-for="(item, index) in this.admission.slice(
                  this.start,
                  this.end
                )"
                :key="index"
                class="flex flex-col text-left md:bg-white md:border-4 md:border-gray-200 md:rounded-xl"
              >
                <div
                  class="flex justify-between lg:gap-2 px-[15px] py-[11px] md:px-[25px] md:pt-[20px] cursor-pointer text-[#001c54]"
                  @click="toggleAccordin(index)"
                >
                  <h3
                    v-html="item.title"
                    class="text-[14px] md:text-[15px] lg:text-[16px] text-white md:text-[#7e7e7e]"
                  ></h3>
                  <div>
                    <span
                      v-if="activeAccordin !== index"
                      class="pt-[10px] pr-[4px]"
                    >
                      <font-awesome-icon
                        icon="fa-angle-down"
                        class="text-[#f8c300] md:text-[#bb1e27]"
                      />
                    </span>
                    <span v-else class="pt-[10px] pr-[4px]">
                      <font-awesome-icon
                        icon="fa-angle-up"
                        class="text-[#f8c300] md:text-[#bb1e27]"
                      />
                    </span>
                  </div>
                </div>
                <p
                  class="text-sm pb-[10px] px-[15px] md:px-[25px] lg:text-md text-white md:text-[#1f1f1f]"
                  v-show="activeAccordin === index"
                  v-html="item.details"
                ></p>
                <hr class="md:hidden" />
              </div>
              <div
                v-if="this.end < this.faqs_list.length"
                class="w-full md:flex md:justify-center md:items-center py-2"
              >
                <button
                  @click="viewMore"
                  class="w-[140px] bg-[#f8c300] border rounded-xl md:rounded-lg py-[6px] md:py-[8px] font-semibold"
                >
                  Load More!
                </button>
              </div>
              <div v-else>
                <button
                  v-if="this.end > 6"
                  @click="viewLess"
                  class="w-[140px] bg-[#f8c300] border rounded-xl md:rounded-lg py-[6px] md:py-[8px] font-semibold"
                >
                  Load Less!
                </button>
              </div>
            </div>
          </div>
          <div v-if="selectedCategory === 'academics'">
            <div class="w-full mx-auto flex flex-col gap-[10px] md:gap-3">
              <div
                v-for="(item, index) in this.academics.slice(
                  this.start,
                  this.end
                )"
                :key="index"
                class="flex flex-col text-left md:bg-white md:border-4 md:border-gray-200 md:rounded-xl"
              >
                <div
                  class="flex justify-between lg:gap-2 px-[15px] py-[11px] md:px-[25px] md:pt-[20px] cursor-pointer text-[#001c54]"
                  @click="toggleAccordin(index)"
                >
                  <h3
                    v-html="item.title"
                    class="text-[14px] md:text-[15px] lg:text-[16px] text-white md:text-[#7e7e7e]"
                  ></h3>
                  <div>
                    <span
                      v-if="activeAccordin !== index"
                      class="pt-[10px] pr-[4px]"
                    >
                      <font-awesome-icon
                        icon="fa-angle-down"
                        class="text-[#f8c300] md:text-[#bb1e27]"
                      />
                    </span>
                    <span v-else class="pt-[10px] pr-[4px]">
                      <font-awesome-icon
                        icon="fa-angle-up"
                        class="text-[#f8c300] md:text-[#bb1e27]"
                      />
                    </span>
                  </div>
                </div>
                <p
                  class="text-sm pb-[10px] px-[15px] md:px-[25px] lg:text-md text-white md:text-[#1f1f1f]"
                  v-show="activeAccordin === index"
                  v-html="item.details"
                ></p>
                <hr class="md:hidden" />
              </div>
              <div
                v-if="this.end < this.faqs_list.length"
                class="w-full md:flex md:justify-center md:items-center py-2"
              >
                <button
                  @click="viewMore"
                  class="w-[140px] bg-[#f8c300] border rounded-xl md:rounded-lg py-[6px] md:py-[8px] font-semibold"
                >
                  Load More!
                </button>
              </div>
              <div v-else>
                <button
                  v-if="this.end > 6"
                  @click="viewLess"
                  class="w-[140px] bg-[#f8c300] border rounded-xl md:rounded-lg py-[6px] md:py-[8px] font-semibold"
                >
                  Load Less!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="pb-14 lg:pb-0">
      <div
        class="w-full bg-white text-[#6a7390] md:bg-[#000a52] h-10 md:h-16 flex items-center justify-center text-[12px] md:text-[14px] md:text-white"
      >
        <h3>© 2023 JAIN ONLINE. All Rights Reserved</h3>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'

export default {
  name: "FaqAccordion",
  data() {
    return {
      categories: ["admissions", "academics"],
      selectedCategory: "admissions",
      activeAccordin: null,
      faqToShow: 4,
      faqs_list: [
        {
          id: 1,
          title:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem soluta, consequuntur distinctio dicta architecto veniam quo labore.",
          details:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem",
        },
        {
          id: 2,
          title:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem soluta, consequuntur distinctio dicta architecto veniam quo labore.",
          details:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem",
        },
        {
          id: 3,
          title:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem soluta, consequuntur distinctio dicta architecto veniam quo labore.",
          details:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem",
        },
        {
          id: 4,
          title:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem soluta, consequuntur distinctio dicta architecto veniam quo labore.",
          details:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem",
        },
        {
          id: 5,
          title:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem soluta, consequuntur distinctio dicta architecto veniam quo labore.",
          details:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem",
        },
        {
          id: 6,
          title:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem soluta, consequuntur distinctio dicta architecto veniam quo labore.",
          details:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem",
        },
        {
          id: 7,
          title:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem soluta, consequuntur distinctio dicta architecto veniam quo labore.",
          details:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem",
        },
        {
          id: 8,
          title:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem soluta, consequuntur distinctio dicta architecto veniam quo labore.",
          details:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem",
        },
        {
          id: 9,
          title:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem soluta, consequuntur distinctio dicta architecto veniam quo labore.",
          details:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos ea quidem",
        },
      ],
      admission: [
        {
          id: 1,
          title:
            "Is the online degree program offered by JAIN (Deemed-to-be University) UGC entitled?",
          details:
            "Yes, the University is entitled by the University Grants Commission (UGC) to offer online degree programs. Additionally, our MBA and MCA programs are approved by AICTE. The curriculum of the electives/courses is designed by industry experts. To sum it up, the program is recognized by UGC and is approved by AICTE respecetively.",
        },
        {
          id: 2,
          title:
            "Is there any difference in Degree Certificates issued for Online and On-Campus/College Programs?",
          details: "No",
        },
        {
          id: 3,
          title: "Do the Online Degrees have a validity period?",
          details:
            "No. The Online Degree that you will receive from JAIN (Deemed-to-be University), Bangalore, is valid for a lifetime.",
        },

        {
          id: 4,
          title: "What is the mode of learning?",
          details:
            "You will have 24*7 access to self-learning material and pre-recorded lectures on our Learning Management System (LMS), which can be accessed on any device.",
        },
        {
          id: 5,
          title: "How many credits does the program have?",
          details:
            "<ul><li>Undergraduate Program - 132 credits</li><li>Postgraduate Program -90 credits</li><li>(1 Credit = 30 Learning hours)</li></ul>",
        },
        {
          id: 6,
          title:
            "How is an online degree better than correspondence, open, part-time, and distance learning?",
          details:
            "An online degree offers real-time interaction, access to multimedia resources, and structured learning environments, providing a more engaging and interactive educational experience compared to traditional part-time, correspondence, or distance learning methods.",
        },
        {
          id: 7,
          title:
            "Are the programs entirely online and do we have downloadable learning material?",
          details:
            "Yes. Everything from application to convocation will be online and you can download the self-learning material from your Learning Management System.",
        },
      ],
      academics: [
        {
          id: 1,
          title:
            "Can International students enroll for online degree programs?",
          details: "Yes",
        },
        //

        {
          id: 2,
          title: "What are the documents required for admission?",
          details:
            "<ul><li>Scanned copy of Passport-size color Photograph.</li><li>Scanned copy of the original Qualifying Examination Certificate / Marksheet and KYC document (like Aadhar Card, PAN, Voter ID etc.)</li><li>Scanned copy of any one of the original documents mentioning Date Of Birth, i.e., X Marksheet or School Leaving Certificate or Transfer Certificate / Character Certificate issued by school authority.</li></ul>",
        },
        {
          id: 3,
          title: "How will I be notified about the admission confirmation?",
          details:
            "Our admission office will contact you about your admission status via your registered email or phone number.",
        },
        {
          id: 4,
          title: "What is the selection process?",
          details:
            "The selection process is based on your eligibility criteria mentioned on the website. Your selection will be confirmed upon successful verification of your documents.",
        },
        {
          id: 5,
          title: "Is there an entrance test?",
          details: "No",
        },
        {
          id: 6,
          title: "Can a student apply for more than one program/elective?",
          details:
            "Yes, but you can pursue only one online degree program/elective at a time.",
        },
        {
          id: 7,
          title: "Are online degree programs offered by JAIN Online expensive?",
          details:
            "JAIN Online offers affordable online degree programs with zero-cost EMI and flexible fee payment options.",
        },
      ],
      error: null,
      start: 0,
      end: 4,
    };
  },
  //   created() {
  //     this.fetchCategories()
  //   },
  computed: {
    showMore() {
      //   return (
      //     // this.selectedCategory &&
      //     // this.faqToShow < (this.selectedCategory.faqs || []).length
      //   )
      return this.faqToShow < this.faqs_list.length;
    },
    showLess() {
      return this.faqToShow > 4 && !this.showMore;
    },
  },
  methods: {
    // fetchCategories() {
    //   axios
    //     .get(`${process.env.VUE_APP_API}/faq/generalfaq-category-list/`)
    //     .then((response) => {
    //       this.categories = response.data.data
    //       if (this.categories.length > 0) {
    //         this.selectedCategory = this.$route.params.slug
    //         this.fetchFaqsByCategory(this.selectedCategory)
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching categories:', error)
    //       this.error = 'Error fetching categories'
    //     })
    // },
    // fetchFaqsByCategory(slug) {
    //   axios
    //     .get(
    //       `${process.env.VUE_APP_API}/faq/generalfaq-list/?generalfaqcategory__slug=${slug}`,
    //     )
    //     .then((resp) => {
    //       this.faqs_list = resp.data.data
    //     })
    //     .catch((err) => {
    //       console.error('Error fetching FAQs by category:', err)
    //       this.error = 'Error fetching FAQs'
    //     })
    // },
    // selectCategory(category) {
    //   this.selectedCategory = category
    //   if (!category.faqs) {
    //     this.fetchFaqsByCategory(category)
    //   }
    // },
    toggleAccordin(index) {
      this.activeAccordin = this.activeAccordin === index ? null : index;
    },
    viewMore() {
      this.end += 2;
    },
    viewLess() {
      this.end = 4;
    },
  },
};
</script>

<style></style>
